<template>
	<div class="main-error-body bg-primary-transparent">
		<div class="page">
			<div class="main-error-wrapper  page page-h ">
				<img src="/assets/img/error/404.png" class="error-page" alt="error">
				<h2>Не найдено</h2>
				<h6> Сервер не может отобразить запрашиваемую страницу по указанному адресу. Возможно она была перемещена или удалена.</h6>
                <a class="btn btn-outline-danger" href="/">Вернуться на главную страницу</a>
			</div>			
		</div>
	</div>
</template>

<script>
export default {
}
</script>